(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
"use strict";

$(document).foundation();

$(document).ready(function () {
    $(".apl__answers__body__column").fadeOut();
    $(".alltime").hide();
    $(".answers-2").hide();
    $(".answers-3").hide();
    $(".answers-4").hide();
    $('.modal-trigger').leanModal();
});

// navigation medium-down
$('#nav-icon4').click(function () {
    $(this).toggleClass('open');
    if ($('#nav-icon4').hasClass("open")) {
        $('.start-span').show();
        $('.history-row').css('border-left: none');
    } else {
        $('.start-span').hide();
        $('.history-row').css('border-left: 2px solid blue');
    }
});

$('#button-One').click(function () {
    console.log("button-One");
});
$('#button-Two').click(function () {
    console.log("button-Two");
});

$(".button").click(function () {

    $('.apl__answers').slideUp();
    if ($(this).hasClass('button__active') && $(this).prop("id") === "button-One") {
        setTimeout(function () {
            $('.answers-1').slideDown('slow');
        }, 500);
    } else if ($(this).hasClass('button__active') && $(this).prop("id") === "button-Two") {
        setTimeout(function () {
            $('.answers-2').slideDown('slow');
        }, 500);
    } else if ($(this).hasClass('button__active') && $(this).prop("id") === "button-Three") {

        setTimeout(function () {
            $('.answers-3').slideDown('slow');
        }, 500);
    } else if ($(this).hasClass('button__active') && $(this).prop("id") === "button-Four") {
        setTimeout(function () {
            $('.answers-4').slideDown('slow');
        }, 500);
    }
    if (!$(this).hasClass('button--selected')) {
        $(this).removeClass('button--unselected');
        $(this).addClass('button--selected');
        $(this).parent().siblings().children().removeClass('button--selected');
        $(this).parent().siblings().children('.button').addClass('button--unselected');
    }
    $(".cronological").fadeToggle("slow");
    $(".alltime").fadeToggle("slow");
    // $( ".answers-1" ).slideToggle( "slow" );
    // $( ".answers-2" ).slideToggle("slow" );
});

},{}],2:[function(require,module,exports){
"use strict";

var audioCtx = new (window.AudioContext || window.webkitAudioContext)(); // define audio context
// Webkit/blink browsers need prefix, Safari won't work without window.

},{}]},{},[1,2]);
